<template>
  <div></div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {
    this.$root.title = "Главная";
    this.getOffer();
  },
  methods: {
    async getOffer() {
      let o = await this.$axios.get("/config/config.registration.offer");
      console.log("oooo", o);
    },
  },
};
</script>
